<template>
  <div v-if="$store.state.loginInfo">

    <!-- sp -->
    <div v-if="$vuetify.breakpoint.xs">
      <v-app-bar app class="header sp">
        <v-container class="d-flex align-center">
          <div style="width:45px;height:45px;">
            <v-img :src="$store.state.loginInfo.img" aspect-ratio="1" class="rounded-circle main_img"></v-img>
          </div>
          <div class="pl-2">{{$store.state.loginInfo.name}}</div>
          <v-spacer></v-spacer>
          <v-btn @click="logout()" class="pa-0" color="primary" small text>ログアウト</v-btn>
        </v-container>
      </v-app-bar>
      <v-main>
        <v-container>
          <router-view />
        </v-container>
      </v-main>
      <v-bottom-navigation app fixed color="primary">
        <v-btn v-for="(nav,i) in navs" :key="i" :style="`width:calc(100% / ${navs.length}); height:100%;background-color:white;`" :to="nav.to" router exact-path>
          <span>{{nav.ttl}}</span>
          <v-badge :value="false" :content="0" color="primary" offset-x="5" offset-y="15">
            <v-icon>{{nav.icon}}</v-icon>
          </v-badge>
        </v-btn>
      </v-bottom-navigation>
    </div>

    <!-- pc -->
    <div v-else>
      <v-navigation-drawer width="200px" app permanent>
        <v-list-item>
          <label>
            <v-list-item-avatar>
              <v-img v-if="!$store.state.loginInfo.img" :src="require('@/assets/images/user.png')"></v-img>
              <v-img v-else-if="$store.state.picture" :src="VUE_APP_BACK_URL + '/storage/images/'+ $store.state.picture"></v-img>
              <v-img v-else :src="VUE_APP_BACK_URL + '/storage/images/'+ $store.state.loginInfo.img"></v-img>
            </v-list-item-avatar>
            <form>
                <input class="file_button" type="file" @change="upload" />
            </form>
          </label>
          <v-list-item-content>
            <v-list-item-title class="px-1 pt-2">{{$store.state.loginInfo.name}}</v-list-item-title>
            <v-btn max-width="75px" class="pa-0" color="primary" small text @click="logout()">ログアウト</v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list nav dense>
          <v-list-item style="border-bottom:none;" v-for="nav in navs" :key="nav.ttl" :to="nav.to" router exact-path>
            <v-list-item-icon>
              <v-badge :value="false" :content="0" color="primary" offset-x="5" offset-y="15">
                <v-icon>{{nav.icon}}</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nav.ttl }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container>
          <router-view />
        </v-container>
      </v-main>
    </div>

  </div>
</template>

<script>
import { myAxios } from "@/plugins/axios";
export default {
  data() {
    return {
      picture: "",
      user: [],
      selectedFile: "",
      result: false,
      isLoading: false,
      VUE_APP_BACK_URL: process.env.VUE_APP_BACK_URL,
    };
  },
  computed: {
    navs() {
      let returnNavs = [
        {
          to: "/main/mytask",
          icon: "mdi-account",
          ttl: "マイタスク",
        },
        {
          to: "/main/task",
          icon: "mdi-playlist-check",
          ttl: "タスク",
        },
        {
          to: "/main/schedule",
          icon: "mdi-calendar",
          ttl: "スケジュール",
        },
        {
          to: "/main/user",
          icon: "mdi-account-group",
          ttl: "ユーザー",
        },
        {
          to: "/main/category",
          icon: "mdi-shape",
          ttl: "案件",
        },
        {
          to: "/main/draft",
          icon: "mdi-archive-edit",
          ttl: "下書き",
        },
        // {
        //   to: "/main/tag",
        //   icon: "mdi-tag",
        //   ttl: "タグ",
        // },
      ];
      if (process.env.NODE_ENV === "development") {
        const devNavs = [
          {
            to: "/main/test/category",
            ttl: "test/category",
          },
          {
            to: "/main/test/tag",
            ttl: "test/tag",
          },
          {
            to: "/main/test/task",
            ttl: "test/task",
          },
          {
            to: "/main/test/user",
            ttl: "test/user",
          },
        ];
        returnNavs = returnNavs.concat(devNavs);
      }
      return returnNavs;
    },
  },
  methods: {
    logout() {
      if (!confirm("ログアウトしますか？")) return;
      this.$store.dispatch("logout");
    },
    async upload(event) {
      this.selectedFile = event.target.files[0];
      let file = event.target.files[0];

      if (this.checkFile(this.selectedFile)) {
        this.picture = await this.getBase64(file);

        const formData = new FormData();
        formData.append("file", this.selectedFile);
        formData.append("fileName", this.selectedFile.name);

        const requestConfig = {
          url: "/user_image",
          method: "POST",
          data: formData,
          params: {
            id: this.$store.state.loginInfo.id,
          },
        };
        myAxios(requestConfig)
          .then((res) => {
            // this.$router.go({
            //   path: this.$router.currentRoute.path,
            // });
            this.$store.commit("setUserPic", this.selectedFile.name);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // deleteImage() {
    //   this.picture = require('@/assets/images/user.png'),
    //   this.show = false
    //   this.$nextTick(function () {
    //     this.show = true
    //   })
    // },
    checkFile(file) {
      this.result = true;
      let SIZE_LIMIT = 5000000; // 5MB
      // キャンセルしたら処理中断
      if (!file) {
        this.result = false;
      }
      // jpeg か png 関連ファイル以外は受付けない
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        this.result = false;
      }
      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        this.result = false;
      }
      return this.result;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  ::v-deep {
    .v-toolbar__content {
      padding: 0 !important;
    }
  }
}
.file_button {
  display: none;
}
</style>
